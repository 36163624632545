.bg-soft-primary {
  background-color: #041d39 !important;
}

button.btn.btn-primary.btn-block.btn-with-loading.is_loading img {
  display: block !important;
}

img.img-fluid.hidden {
  display: none;
}

.profile-user-wid {
  margin-top: -26px;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.avatar-title {
  align-items: center;
  background-color: #556ee6;
  color: #fff;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.bg-light {
  background-color: #eff2f7 !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #556ee6 !important;
  border-color: #556ee6;
}