.App {
    font-family: sans-serif;
    text-align: center;
}


.container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
  }
}

#loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
}