@charset "UTF-8";
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0)
}
body {
  margin: 0;
  font-family: Poppins,sans-serif;
  font-size: .8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  background-color: #f8f8fb
}

a {
  color: #556ee6;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
  font-weight: 500;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .94rem + 2px);
  padding: .47rem .75rem;
  font-size: .8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.card {
  word-wrap: break-word;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.21875rem;
  padding-left: 1.5rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.109375rem;
  opacity: 0;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

  .custom-control-label::before {
    position: absolute;
    top: .109375rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    pointer-events: none;
    background-color: #fff;
    border: 1px solid #adb5bd;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

.custom-checkbox .custom-control-label::before {
  border-radius: .25rem;
}

.custom-control-label::after {
  background: no-repeat 50%/50% 50%;
}

.custom-control-label::after, .custom-control-label::before {
  position: absolute;
  top: .109375rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}

.btn-block {
  display: block;
  width: 100%;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #495057;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: .47rem .75rem;
  font-size: .8125rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.text-primary {
  color: #556ee6 !important;
}

.fixed-top, .sb-nav-fixed #layoutSidenav #layoutSidenav_nav, .sb-nav-fixed .sb-topnav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}


/* Usefull */

#layoutSidenav {
  display: flex;
}

  #layoutSidenav #layoutSidenav_nav {
    flex-basis: 225px;
    flex-shrink: 0;
    transition: transform 0.15s ease-in-out;
    z-index: 1038;
    transform: translateX(-225px);
  }

  #layoutSidenav #layoutSidenav_content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    flex-grow: 1;
    min-height: calc(100vh - 56px);
    margin-left: -225px;
  }

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
  transform: translateX(0);
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1037;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

@media (min-width: 992px) {
  #layoutSidenav #layoutSidenav_nav {
    transform: translateX(0);
  }

  #layoutSidenav #layoutSidenav_content {
    margin-left: 0;
    transition: margin 0.15s ease-in-out;
  }

  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
    transform: translateX(-225px);
  }

  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content {
    margin-left: -225px;
  }

    .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
      display: none;
    }
}

.sb-nav-fixed .sb-topnav {
  z-index: 1039;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
  width: 225px;
  height: 100vh;
  z-index: 1038;
}

  .sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
    padding-top: 56px;
  }

    .sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
      overflow-y: auto;
    }

.sb-nav-fixed #layoutSidenav #layoutSidenav_content {
  padding-left: 225px;
  top: 56px;
}

#layoutError {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

  #layoutError #layoutError_content {
    min-width: 0;
    flex-grow: 1;
  }

  #layoutError #layoutError_footer {
    min-width: 0;
  }

.img-error {
  max-width: 20rem;
}

.nav .nav-link .sb-nav-link-icon,
.sb-sidenav-menu .nav-link .sb-nav-link-icon {
  margin-right: 0.5rem;
}

.sb-topnav {
  padding-left: 0;
  height: 56px;
  z-index: 1039;
}

  .sb-topnav .navbar-brand {
    width: 225px;
    margin: 0;
  }

  .sb-topnav.navbar-dark #sidebarToggle {
    color: rgba(255, 255, 255, 0.5);
  }

  .sb-topnav.navbar-light #sidebarToggle {
    color: #212529;
  }

.sb-sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
}

  .sb-sidenav .sb-sidenav-menu {
    flex-grow: 1;
  }

    .sb-sidenav .sb-sidenav-menu .nav {
      flex-direction: column;
      flex-wrap: nowrap;
    }

      .sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-heading {
        padding: 1.75rem 1rem 0.75rem;
        font-size: 0.75rem;
        font-weight: bold;
        text-transform: uppercase;
      }

      .sb-sidenav .sb-sidenav-menu .nav .nav-link {
        display: flex;
        align-items: center;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        position: relative;
      }

        .sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-nav-link-icon {
          font-size: 0.9rem;
        }

        .sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-sidenav-collapse-arrow {
          display: inline-block;
          margin-left: auto;
          transition: transform 0.15s ease;
        }

        .sb-sidenav .sb-sidenav-menu .nav .nav-link.collapsed .sb-sidenav-collapse-arrow {
          transform: rotate(-90deg);
        }

      .sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested {
        margin-left: 1.5rem;
        flex-direction: column;
      }

  .sb-sidenav .sb-sidenav-footer {
    padding: 0.75rem;
    flex-shrink: 0;
  }

.sb-sidenav-dark {
  background-color: #212529;
  color: rgba(255, 255, 255, 0.5);
}

  .sb-sidenav-dark .sb-sidenav-menu .sb-sidenav-menu-heading {
    color: rgba(255, 255, 255, 0.25);
  }

  .sb-sidenav-dark .sb-sidenav-menu .nav-link {
    color: rgba(255, 255, 255, 0.5);
  }

    .sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-nav-link-icon {
      color: rgba(255, 255, 255, 0.25);
    }

    .sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
      color: rgba(255, 255, 255, 0.25);
    }

    .sb-sidenav-dark .sb-sidenav-menu .nav-link:hover {
      color: #fff;
    }

    .sb-sidenav-dark .sb-sidenav-menu .nav-link.active {
      color: #fff;
    }

      .sb-sidenav-dark .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
        color: #fff;
      }

  .sb-sidenav-dark .sb-sidenav-footer {
    background-color: #343a40;
  }

.sb-sidenav-light {
  background-color: #f8f9fa;
  color: #212529;
}

  .sb-sidenav-light .sb-sidenav-menu .sb-sidenav-menu-heading {
    color: #adb5bd;
  }

  .sb-sidenav-light .sb-sidenav-menu .nav-link {
    color: #212529;
  }

    .sb-sidenav-light .sb-sidenav-menu .nav-link .sb-nav-link-icon {
      color: #adb5bd;
    }

    .sb-sidenav-light .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
      color: #adb5bd;
    }

    .sb-sidenav-light .sb-sidenav-menu .nav-link:hover {
      color: #0d6efd;
    }

    .sb-sidenav-light .sb-sidenav-menu .nav-link.active {
      color: #0d6efd;
    }

      .sb-sidenav-light .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
        color: #0d6efd;
      }

  .sb-sidenav-light .sb-sidenav-footer {
    background-color: #e9ecef;
  }
