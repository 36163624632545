.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensures it sits above other content */
}

.loading-indicator {
  font-size: 1.5rem;
  color: #333; /* Adjust color as needed */
}
