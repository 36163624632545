body #page-topbar {
  background-color: #041d39;
}

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: #041d39;
  box-shadow: 0 .75rem 1.5rem rgba(18,38,63,.03);
}

.navbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 calc(24px / 2) 0 0;
}

.d-flex {
  display: flex !important;
}

body .navbar-brand-box {
  width: auto;
}

.navbar-brand-box {
  padding: 0 1.5rem;
  text-align: center;
  width: 250px;
}

.logo {
  
}


.topnav {
  background: #fff;
  box-shadow: 0 .75rem 1.5rem rgba(18,38,63,.03);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
}

.topnav .topnav-menu {
  margin: 0;
  padding: 0;
}


.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.topnav .navbar-nav .nav-link {
  font-size: 14px;
  position: relative;
  padding: 1rem 1.3rem;
  padding-left: 1.3rem;
  color: #545a6d;
}

.card {
  margin-bottom: 24px;
  box-shadow: 0 .75rem 1.5rem rgba(18,38,63,.03);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #f6f6f6;
  border-radius: .25rem;
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f6f6f6;
  border-bottom: 0 solid #f6f6f6;
}

.border-primary {
  border-color: #556ee6 !important;
}

.text-primary {
  color: #556ee6 !important;
}

h5 {
  font-size: 1.015625rem;
}

.bg-transparent {
  background-color: transparent !important;
}

.iframe-container {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
}

iframe {
  border: none;
  height: 100%;
  width: 100%;
}